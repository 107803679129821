import React from "react";

const SocialMediaIcons = () => {
    return (
        <div className="icons">
                <div className="icon"><a href="https://www.facebook.com/forestspakosmetologia"><img
                    src="/images/facebook.svg" alt="FB" /></a></div>
                <div className="icon"><a href="https://www.instagram.com/forestspa_kosmetologia_masaz/"><img
                    src="/images/instagram.svg" alt="IG" /></a></div>
        </div>
    )};

export default SocialMediaIcons;