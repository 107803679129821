import React from "react";

const Address = () => {
    return (
        <div>
           <ul>
                <li>Forest Spa Kosmetologia i Masaż</li>
                <li>ul. Jana Nowaka Jeziorańskiego 46 lok. U12</li>
                <li>03-982 Warszawa</li>
           </ul>
        </div>
    )}

export default Address;
