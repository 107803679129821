import React from "react";

const Telephone = () => {
    return (
        <div>
            <li><strong>Tel.</strong>+48 517 225 552</li>
        </div>
    )};

export default Telephone;

