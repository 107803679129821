export const dataTreatment = [ {
    categoryName: "Kosmetologia twarzy - zabiegi",
    categoryTreatments : [
        {
            treatmentName: "Zabieg spersonalizowany*",
            treatmentPrice: "420zł"
        },
    {
        treatmentName: "Christina Comodex - kompleksowy zabieg oczyszczający",
        treatmentPrice: "380zł"
    },
        {
            treatmentName: "Acnelan - leczniczy zabieg do cery trądzikowej",
            treatmentPrice: "450zł"
        },
    {
        treatmentName: "M.E. Line (zabieg złuszczający)",
        treatmentPrice: "400zł"
    },
        {
            treatmentName: "BioRePeel - zabieg rewitalizujący, napinający",
            treatmentPrice: "450zł"
        },
    {
        treatmentName: "Peel Mission Lift Peel zabieg zagęszczający + serum do pielęgnacji domowej",
        treatmentPrice: "450zł"
    },
        {
            treatmentName: "ProXn KSANTOHUMOL - 1 stopień",
            treatmentPrice: "450zł"
        },
        {
            treatmentName: "ProXn KSANTOHUMOL - 2 stopień",
            treatmentPrice: "500zł"
        },
        {
            treatmentName: "ProXn Acne Rescue Treatment",
            treatmentPrice: "470zł"
        },
        {
            treatmentName: "ProXn Bright Up z ampułką do pielegnacji domowej",
            treatmentPrice: "590zł"
        },
        {
            treatmentName: "ProXn Boost Up z ampułką do pielegnacji domowej",
            treatmentPrice: "590zł"
        },
        {
            treatmentName: "ProXn 3 stopień z mezoterapią mikroigłową",
            treatmentPrice: "950zł"
        },
        {
            treatmentName: "*Christina, Organic Series, Peel Mission",
            treatmentPrice: ""
        }
    ]},
    {
        categoryName: "Kosmetologia twarzy - terapie manualne",
        categoryTreatments : [
            {
                treatmentName: " - autorski masaż liftingujący, drenujący, wysmuklający",
                treatmentTitle: "Magia Młodości",
                treatmentPrice: "300zł"
            },
            {
                treatmentName: " - lifting bez skalpela, subtelnie intensywny",
                treatmentTitle: "Holistic KOBIDO",
                treatmentPrice: "350zł"
            },
            {
                treatmentName: " - autorska wersja Kobido w mistycznej podróży dla twarzy i duszy",
                treatmentTitle: "Mystic KOBIDO Trip",
                treatmentPrice: "370zł"
            },
            {
                treatmentName: " - manualne estetyczne modelowanie twarzy",
                treatmentTitle: "Zoga Face Integration",
                treatmentPrice: "350zł"
            },
            {
                treatmentName: " - energetyczny masaż na punktach Marma (ajurweda), wyciszający układ nerwowy",
                treatmentTitle: "Face Marma",
                treatmentPrice: "330zł"
            },
            {
                treatmentName: " - połączenie pracy z powięzią, technik transbukalnych, akupunktury kosmetycznej, baniek próżniowych i kosmetyki białej",
                treatmentTitle: "Face Bloom Therapy",
                treatmentPrice: "500zł"
            },
            {
                treatmentName: " / jako dodatek",
                treatmentTitle: "Akupunktura kosmetologiczna",
                treatmentPrice: "150/100zł"
            },
            {
                treatmentName: " - technika manualna wewnątrz jamy ustnej oraz nosa  / jako dodatek",
                treatmentTitle: "Transbukal",
                treatmentPrice: "150/100zł"
            }
        ]},
    {
        categoryName: "Kosmetologia twarzy - zabiegi iniekcyjne",
        categoryTreatments : [
    {
        treatmentName: "mezoterapia twarz",
        treatmentPrice: "650zł"
    },
    {
        treatmentName: "mezoterapia twarz, szyja, dekolt",
        treatmentPrice: "850zł"
    },
    {
        treatmentName: "peptydowa terapia skóry głowy dr Cyj",
        treatmentPrice: "650zł"
    },
    {
        treatmentName: "mikropen - mezoterapia mikroigłowa twarz / z szyją / z dekoltem",
        treatmentPrice: "700/750/850zł"
    },
            {
                treatmentName: "mikropen - mezoterapia mikroigłowa + BioRePeelCl3 twarz / z szyją / z dekoltem",
                treatmentPrice: "800/850/950zł"
            },
            {
                treatmentName: "stymulator tkankowy - okolice oka Jalupro, Sunecos, Vitaran",
                treatmentPrice: "750zł"
            },
            {
                treatmentName: "stymulator tkankowy - dolne partie twarzy/szyja Nucleofill, Fillagen, Sisthaema",
                treatmentPrice: "1100zł"
            }
    ]},
    {
        categoryName: "Relaks - zabiegi na ciało",
        categoryTreatments : [
            {
                treatmentName: " - bandażowanie ciała, programy modelujące i antycellulitowe brzuch/ciało",
                treatmentTitle: "AROSHA",
                treatmentPrice: "300/350zł"
            },
            {
                treatmentName: " (Kąpiel w wannie, peeling, serum, maska, shea balsam - 110min 400zł; Kąpiel w wannie, peeling, masaż całego ciała - 140min 450zł)",
                treatmentTitle: "Rytuał kozie mleko i chińska śliwka",
                treatmentPrice: "400/450zł"
            },
            {
                treatmentName: " (Kąpiel w wannie, peeling, shea balsam - 80min 300zł; Kąpiel w wannie, peeling, masaż całego ciała - 140min 450zł)",
                treatmentTitle: "Rytuał pomarańcza i chili",
                treatmentPrice: "300/450zł"
            },
            {
                treatmentName: " (Kąpiel w wannie, peeling, serum, maska, mgiełka, shea balsam - 120min 420zł; Kąpiel w wannie, peeling, mgiełka, masaż całego ciała - 150min 470zł)",
                treatmentTitle: "Rytuał czarna orchidea",
                treatmentPrice: "420/470zł"
            }
        ]},
    {
        categoryName: "Relaks - masaże ciała",
        categoryTreatments : [
            {
                treatmentName: " (bestseller!) - peeling z rękawica kessa, subtelny masaż ciała i manualny lifting twarzy 150min",
                treatmentTitle: "Rytuał otulONA / otulONY",
                treatmentPrice: "650zł"
            },
            {
                treatmentName: " (bestseller!) autorski rytuał masażu ciała, głowy i twarzy 110min",
                treatmentTitle: "Forest Touch",
                treatmentPrice: "400zł"
            },
            {
                treatmentName: " - mistyczna podróż prowadzona głosem i dźwiękiem z masażem ciała 100min",
                treatmentTitle: "Sound Healing Massage",
                treatmentPrice: "500zł"
            },
    {
        treatmentName: "relaksacyjny 60/90 min",
        treatmentPrice: "240/300zł"
    },
            {
                treatmentName: "masaż pleców 40min",
                treatmentPrice: "180zł"
            },
            {
                treatmentName: "antycellulitowy bańką chińską 30/45 min ",
                treatmentPrice: "150/190zł"
            },
            {
                treatmentName: "masaż peelingujący 60min",
                treatmentPrice: "280zł"
            },
            {
                treatmentName: "świecowanie uszu hopi / konchy z masażem głowy i małżowiny usznej",
                treatmentPrice: "200zł"
            },
            {
                treatmentName: "świecowanie uszu single / jako dodatek ",
                treatmentPrice: "100zł"
            }
            ]},
    {
        categoryName: "Kosmetyka - pielęgnacja dłoni i stóp",
        categoryTreatments : [
            {
                treatmentName: "manicure klasyczny",
                treatmentPrice: "130zł"
            },
            {
                treatmentName: "pedicure klasyczny",
                treatmentPrice: "170zł"
            },
            {
                treatmentName: "manicure hybrydowy",
                treatmentPrice: "160zł"
            },
            {
                treatmentName: "pedicure hybrydowy",
                treatmentPrice: "220zł"
            },
            {
                treatmentName: "zdjęcie hybrydy",
                treatmentPrice: "40zł"
            },
            {
                treatmentName: "FRENCH",
                treatmentPrice: "+20zł"
            },
            {
                treatmentName: "malowanie paznokci klasycznie",
                treatmentPrice: "70zł"
            },
            {
                treatmentName: "malowanie paznokci hybrydą",
                treatmentPrice: "100zł"
            },
            {
                treatmentName: "manicure japoński",
                treatmentPrice: "130zł"
            },
            {
                treatmentName: "Kwasy do pedicure",
                treatmentPrice: "60zł"
            },
            {
                treatmentName: "VOESH rękawiczki/skarpetki kolagenowe",
                treatmentPrice: "45zł"
            }
        ]},
    {
        categoryName: "Kosmetyka - pielęgnacja oprawy oka",
        categoryTreatments : [
            {
                treatmentName: "henna brwi",
                treatmentPrice: "60zł"
            },
            {
                treatmentName: "henna brwi + regulacja pęsetą",
                treatmentPrice: "70zł"
            },
            {
                treatmentName: "henna brwi + regulacja Lycon",
                treatmentPrice: "90zł"
            },
            {
                treatmentName: "henna pudrowa brwi + geometria + regulacja",
                treatmentPrice: "120zł"
            },
            {
                treatmentName: "henna brwi i rzęs + regulacja pęsetą",
                treatmentPrice: "100zł"
            },
            {
                treatmentName: "henna brwi i rzęs + regulacja Lycon",
                treatmentPrice: "110zł"
            },
            {
                treatmentName: "henna rzęs",
                treatmentPrice: "60zł"
            },
            {
                treatmentName: "henna rzęs + regulacja",
                treatmentPrice: "75zł"
            },
            {
                treatmentName: "henna rzęs + regulacja Lycon",
                treatmentPrice: "90zł"
            },
            {
                treatmentName: "lifting i laminacja rzęs + farbka + BOTOX",
                treatmentPrice: "230zł"
            },
            {
                treatmentName: "laminacja brwi + farbka + BOTOX",
                treatmentPrice: "210zł"
            },
            {
                treatmentName: "regulacja brwi Lycon",
                treatmentPrice: "60zł"
            },
            {
                treatmentName: "regulacja brwi pęsetą",
                treatmentPrice: "50zł"
            }
            ]},
    {
        categoryName: "Kosmetyka - make up",
        categoryTreatments : [
            {
                treatmentName: "makijaż dzienny",
                treatmentPrice: "250zł"
            },
            {
                treatmentName: "makijaż okolicznościowy",
                treatmentPrice: "300zł"
            }
        ]},
    {
        categoryName: "Kosmetyka - makijaż permanentny",
        categoryTreatments : [

            {
                treatmentName: "*w cenę wliczona jest korekta/dopigmentowanie",
                treatmentPrice: ""
            },
            {
                treatmentName: "brwi metoda cienia/ombre",
                treatmentPrice: "1200zł"
            },
            {
                treatmentName: "ust z cieniowaniem",
                treatmentPrice: "1300zł"
            }
            ]},

    {
        categoryName: "Kosmetyka - depilacja systemem Lycon",
        categoryTreatments : [
    {
        treatmentName: "bikini głębokie",
        treatmentPrice: "170zł"
    },
    {
        treatmentName: "bikini płytkie",
        treatmentPrice: "130zł"
    },
    {
        treatmentName: "pachy",
        treatmentPrice: "80zł"
    },
    {
        treatmentName: "wąsik",
        treatmentPrice: "50zł"
    },
            {
                treatmentName: "brwi/nos/baki/broda",
                treatmentPrice: "60zł"
            },
            {
                treatmentName: "twarz",
                treatmentPrice: "160zł"
            },
            {
                treatmentName: "uszy",
                treatmentPrice: "60zł"
            }
            ]},

    {
        categoryName: "Kosmetyka - depilacja woskiem klasycznym",
        categoryTreatments : [
            {
                treatmentName: "dłonie/stopy",
                treatmentPrice: "50zł"
            },
            {
                treatmentName: "łydki + kolana",
                treatmentPrice: "80zł"
            },
            {
                treatmentName: "całe nogi",
                treatmentPrice: "110zł"
            },
            {
                treatmentName: "uda",
                treatmentPrice: "80zł"
            },
            {
                treatmentName: "pośladki",
                treatmentPrice: "70zł"
            },
            {
                treatmentName: "przedramiona",
                treatmentPrice: "70zł"
            },
            {
                treatmentName: "pachy",
                treatmentPrice: "60zł"
            },
            {
                treatmentName: "całe ręce",
                treatmentPrice: "90zł"
            },
            {
                treatmentName: "plecy + ramiona (MEN)",
                treatmentPrice: "170zł"
            },
            {
                treatmentName: "plecy (MEN)",
                treatmentPrice: "150zł"
            },
            {
                treatmentName: "tors + brzuch (MEN)",
                treatmentPrice: "160zł"
            },
            {
                treatmentName: "tors (MEN)",
                treatmentPrice: "120zł"
            },
            {
                treatmentName: "brzuch (MEN)",
                treatmentPrice: "110zł"
            }
            ]},
    {
        categoryName: "Usługi dodatkowe*",
        categoryTreatments : [

            {
                treatmentName: "Medycyna chińska",
                treatmentPrice: ""
            },
            {
                treatmentName: "Japoński masaż KIKO",
                treatmentPrice: ""
            },
            {
                treatmentName: "*cennik dostępny w salonie",
                treatmentPrice: ""
            }
        ]}
            ];

