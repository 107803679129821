import React from "react";

const Maps = () => {
    return (
        <div>
            <div className="div_map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.743895768913!2d21.07450531568552!3d52.229870579760345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecd0e105b9531%3A0xf10b6cc55262fe15!2sForest%20SPA%20Kosmetologia%20i%20Masa%C5%BC!5e0!3m2!1spl!2spl!4v1602340154802!5m2!1spl!2spl"
                    style={{width:"80%", height:"400px", frameBorder:"0", style:"border:0"}} allowFullScreen="" aria-hidden="false" title="maps"
                    tabIndex="0"></iframe>
            </div>
        </div>
    )
};

export default Maps;
