import React from "react";

const MiddleSectionText = () => {
    return (
        <div>
                <div className="middle-text-content">
                    <p>Przyjdź do nas odpocząć, zadbać o siebie, podzielić się z nami swoją energią. W kameralnym otoczeniu, w przemiłej atmosferze, z uśmiechami na twarzach, z doświadczeniem i profesjonalizmem - czekamy na Ciebie</p>
                </div>
        </div>
)};

export default MiddleSectionText;