import React from "react";

import MainBody from "./MainBody";
import OfferPage from "./OfferPage";
import Team from "./Team";

const Main = () => {
    return (
        <div>
            <MainBody/>
            <OfferPage/>
            <Team/>
        </div>
)};

export default Main;

