export const photos = [
        {
        src: "./images/forest-1.JPG",
        width: 15,
        height: 10
        },
        {
        src: "./images/forest-44.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-9.JPG",
        width: 1416,
        height: 944
        },
        {
        src: "./images/forest-33.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-25.JPG",
        width: 1441,
        height: 961
        },
        {
        src: "./images/forest-32.JPG",
        width: 1459,
        height: 973
        },
        {
        src: "./images/forest-14.JPG",
        width: 15,
        height: 10
        },
        {
        src: "./images/forest-186.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-2.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-116.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-113.JPG",
        width: 1459,
        height: 973
        },
        {
        src: "./images/forest-160.JPG",
        width: 15,
        height: 10
        },
        {
        src: "./images/forest-79.JPG",
        width: 15,
        height: 10
        },
        {
        src: "./images/forest-6.JPG",
        width: 1374,
        height: 916
        },
        {
        src: "./images/forest-133.JPG",
        width: 15,
        height: 10
        },
        {
        src: "./images/forest-139.JPG",
        width: 961,
        height: 1441
        },
        {
        src: "./images/forest-23.JPG",
        width: 15,
        height: 10
        },
        {
        src: "./images/forest-15.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-2.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-179.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-120.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-2.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-105.JPG",
        width: 15,
        height: 10
        },
        {
        src: "./images/forest-116.JPG",
        width: 958,
        height: 1437
        },
        {
        src: "./images/forest-33.JPG",
        width: 10,
        height: 15
        },
        {
        src: "./images/forest-16.JPG",
        width: 1416,
        height: 944
        },
        {
        src: "./images/forest-137.JPG",
        width: 15,
        height: 10
        }
        ];