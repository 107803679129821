import React from "react";

const OpeningHours = () => {
    return (
        <div>
                <ul className="contact-details">
                    <li><strong>Godziny otwarcia:</strong></li>
                    <li>poniedziałek: 11:00 - 21:00</li>
                    <li>wtorek-piątek: 09:00 - 21:00</li>
                    <li>sobota: 09:00 - 16:00</li>
                    <li>niedziela: ZAMKNIĘTE</li>
                </ul>
        </div>
    )};

export default OpeningHours;