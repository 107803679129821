import React from "react";

const ContactIcons = () => {
    return (
            <div>
                <img src="images/location.svg" className="contact-icon" alt="location" width="40px"/>
                <img src="images/old-phone.svg" className="contact-icon" alt="phone" width="40px"/>
            </div>
)};

export default ContactIcons;